import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Justice Partners
			</title>
			<meta name={"description"} content={"Експертні юридичні рішення з індивідуальним підходом"} />
			<meta property={"og:title"} content={"Justice Partners"} />
			<meta property={"og:description"} content={"Експертні юридичні рішення з індивідуальним підходом"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="link" />
			<Override slot="icon4" />
			<Override slot="box" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ми в Justice Partners пишаємося своїм глибоким розумінням закону та орієнтованим на клієнта підходом.
					<br />
					<br />
					{" "}Наші досвідчені адвокати спеціалізуються в різних галузях права, що дозволяє нам пропонувати комплексні юридичні рішення, адаптовані до ваших конкретних потреб.{" "}
					<br />
					<br />
					Ми прагнемо досягти найкращих можливих результатів для наших клієнтів, зосереджуючись на чесності, захисті інтересів і розумінні.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Персоналізоване обслуговування
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми прислухаємося до ваших потреб і налаштовуємо наші послуги відповідно до них.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Досвідчені професіонали
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Наша команда має підтверджену історію успіху в різних юридичних сферах.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Ефективні рішення
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми надаємо ефективні та ефективні юридичні рішення, які заощадять ваш час і стрес.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-7">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Знайдіть свої відповіді тут
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Які послуги надає ваша юридична фірма?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						JUSTICE PARTNERS надає широкий спектр юридичних послуг, включаючи консультації, представництво в суді, складання та аналіз договорів, правову допомогу у питаннях корпоративного права, сімейного права, трудового права та захист інтелектуальної власності.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можу я отримати консультацію онлайн?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми пропонуємо онлайн-консультації через відеозв'язок, телефон або електронну пошту. Для цього необхідно попередньо записатися через наш вебсайт або зателефонувати до офісу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Скільки коштують ваші послуги?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Вартість наших послуг залежить від типу послуги та складності справи. Для отримання детальної інформації, будь ласка, зв'яжіться з нами для проведення первинної консультації, після чого ми зможемо надати вам орієнтовний кошторис.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи працюєте ви з фізичними особами чи тільки з компаніями?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						JUSTICE PARTNERS працює як з фізичними особами, так і з компаніями, надаючи юридичні послуги в різних сферах права.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можете ви представляти мене в суді?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, наші адвокати мають досвід представництва клієнтів у судах різних інстанцій, включаючи районні, апеляційні та касаційні суди.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як довго триває розгляд справи?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Тривалість розгляду справи залежить від її складності та судової інстанції. Ми завжди прагнемо максимально швидко і ефективно вирішити справу наших клієнтів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text5" />
			<Override slot="text4" />
			<Override slot="box3" />
			<Override slot="icon2" />
			<Override slot="icon1" />
			<Override slot="icon" />
			<Override slot="text1" />
			<Override slot="button" />
			<Override slot="button1" />
			<Override slot="box" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});